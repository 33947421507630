<template>
  <div>
    <div class="member">
      <el-image class="img" :src="require('@as/serve/card.png')"></el-image>
      <div class="info">
        <div class="title">
          <div class="name">李青峰</div>
          <div class="tag">个人主页</div>
        </div>
        <div class="subtitle">
          <div class="post">新浪微博 研发总监</div>
          <div class="tag">北京</div>
        </div>
        <div class="detail">
          奥斯汀·柯林斯（Austen Collins）是Serverless
          Inc.（Serverless.com）的首席执行官和创奥斯汀·柯林斯（Austen
          Collins）是Serverless
          Inc.（Serverless.com）的首席执行官和创始人，该公司为开发人员和组织在无服务器云服务上构建应用程序的工具提供工具。他于2015年创建了无服务器框架，这是一种无服务器应用程序框架，此框架已成长为世界上最受欢迎的开源项目之一。他也是CloudEvents的创造者之一，事件数据的标准格式，并支持无服务器上发表演讲代云本地计算的基础。
        </div>
      </div>
    </div>
    <div class="certificate">
      <div class="title">
        <el-image
          class="icon"
          :src="require('@as/serve/member_detail__icon.png')"
        ></el-image>
        <div class="label">
          <div class="name">荣誉证书</div>
          <div class="en">HONOR</div>
        </div>
      </div>
      <div class="detail">
        <el-image class="img" :src="require('@as/serve/banner.png')"></el-image>
        <div class="info">
          <div class="row">授予时间： 2019-11-12</div>
          <div class="row">擅长领域： 云计算，无服务器</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
  },
}
</script>

<style lang="less" scoped>
.member {
  padding: 74px 390px 56px;
  display: flex;
  justify-content: center;
  background: #2a2a2a;
  box-sizing: border-box;
  .img {
    width: 240px;
    height: 240px;
    display: block;
  }
  .info {
    margin: 27px 0 0 92px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a38f5d;
      }
      .tag {
        padding: 8px 14px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1f1f1f;
        background: #a38f5d;
        border: 1px solid #a38f5d;
        border-radius: 15px;
      }
    }
    .subtitle {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .post {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a38f5d;
        line-height: 24px;
      }
      .tag {
        padding: 7px 14px;
        margin-left: 30px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #928054;
        border: 1px solid #a38f5d;
        border-radius: 15px;
      }
    }
    .detail {
      margin-top: 24px;
      width: 780px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      line-height: 24px;
      color: #cccccc;
    }
  }
}
.certificate {
  padding: 39px 390px 60px;
  background: #1f1f1f;
  .title {
    display: flex;
    align-items: center;
    .icon {
      width: 98px;
      height: 63px;
      display: block;
    }
    .label {
      margin-left: 12px;
      .name {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 500;
        color: #a38f5d;
      }
      .en {
        margin-top: 6px;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 300;
        color: #a38f5d;
      }
    }
  }
  .detail {
    margin-top: 18px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    .img {
      width: 240px;
      height: 170px;
      display: block;
    }
    .info {
      margin-left: 52px;
      .row {
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a38f5d;
        &:first-child {
          margin-bottom: 26px;
        }
      }
    }
  }
}
</style>